import React from 'react'
import styled from 'styled-components'
import { ContainerTime } from '../TimerPopup.js/styles'
import Timer from '../TimerPopup.js/Timer'

export default function index({ dueDay, dueTime }) {


    return (
        <TimerBanner>
            <BannerTitle className="grad">Última Chance</BannerTitle>
            <Text>Quando timer chegar ao final as vendas serão encerradas</Text>
            {/* <Off>20% OFF</Off> */}
            <ContainerTime>
                <Timer dueDay={dueDay} dueTime={dueTime}></Timer>
            </ContainerTime>
        </TimerBanner>
    )
}


const Off = styled.div`
background: var(--grad-orange);
color: black;
font-family: 'Archivo Black', sans-serif;
padding: 10px;
font-size: 40px;
border-radius: 3px;
margin: 10px;
margin-top: 20px;

@media only screen and (max-width: 670px) {
    font-size:30px;
}
`

const BannerTitle = styled.h1`
background: var(--grad-orange);
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
background-clip: text;
font-size:45px;
line-height: 50px;

@media only screen and (max-width: 670px) {
    font-size:35px;
    line-height: 35px;

}
`
const Text = styled.div`
font-size: 20px;
max-width: 460px;
`
const TimerBanner = styled.section`
{
    width:100%;
    padding: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background:black;
    color: white;
    text-align: center;
}
`
