import styled from 'styled-components'

export const Container = styled.header`
  width: 100%;
  min-height: 80px;
  padding: 25px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-gray-light);
    margin-left: 25px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  @media(max-width: 900px) {
    .flex nav {
      display: none;
    }
  }

  @media(max-width: 520px) {
    .flex {
      display: none;
    }

    justify-content: center;
  }
`

export const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--text-dark);
  white-space: nowrap;

  background: var(--grad-blue-light);
  padding: 12px;
  border-radius: var(--border-radius);
  transition: transform .3s;

  &:hover {
    box-shadow: var(--box-shadow-blue);
		animation: pulse 0.9s infinite;
		animation-timing-function: linear;
	}

	@keyframes pulse {
		0% { transform: scale(1); }
		50% { transform: scale(1.1); }
		100% { transform: scale(1); }
	}
`
