import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  h2 {
    max-width: 335px;
  }

  div {
    display: flex;
    margin-left: 60px;

    img {
      height: 70px;
      margin: 14px 16px 0 0;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    h2 {
      max-width: 360px;
    }

    div {
      margin: 26px 0 0;

      img {
        height: 60px;
      }
    }
  }

  @media (max-width: 520px) {
    h2 {
      max-width: 250px;
    }

    div {
      img {
        height: 56px;
        margin: 6px 14px 0 0;
      }
    }
  }
`
