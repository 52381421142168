import { Container } from './styles'
import { Text } from '../../../components/Texts'

import guaranteeStamp from '../../../../assets/images/guarantee-stamp.svg'

function WarrantySection({ id }) {
  return (
    <Container id={id}>
      <div className="content">
        <img src={guaranteeStamp} alt='Electron' />

        <div>
          <Text fontSize='22px' margin='10px 0' bold>
            RISCO ZERO! GARANTIA INCONDICIONAL DE 7 DIAS
          </Text>

          <Text>
            É isso mesmo, você não tem risco nenhum ao se inscrever neste curso! Você vai ter 7 dias
            para testar tudo, assistir todas as aulas que quiser e se você não ficar satisfeito,
            por qualquer motivo, é só pedir que eu te dou 100% do dinheiro de volta,
            sem burocracia e sem perguntas.
          </Text>
        </div>
      </div>
    </Container>
  )
}

export default WarrantySection
