import { Container, Content } from './styles'
import SlashTitle from '../../../components/SlashTitle'
import GridCardDashed from '../../../components/GridCardDashed'
import ButtonSubscribe from '../../../components/ButtonSubscribe'

import arrayBenefits from './arrayBenefits'

function BenefitsSection({ id }) {
  return (
    <Container id={id}>
      <Content>
        <SlashTitle>
          BENEFÍCIOS EXCLUSIVOS E IMPERDÍVEIS
        </SlashTitle>

        <GridCardDashed arrayItems={arrayBenefits} className='grid' />

        <ButtonSubscribe />
      </Content>
    </Container>
  )
}

export default BenefitsSection
