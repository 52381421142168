import { Section, Container, ContainerButtons, ContainerTime } from './styles'
import { SubTitle } from '../Texts'
import Timer from './Timer'
import { useEffect, useState } from 'react'
import LinkPay from '../LinkPay'
import BlackFridayBanner from '../BlackFridayBanner'
import styled from 'styled-components'

function TimerPopup({ title, dueDay, dueTime, launchSeconds }) {

    const [showPopUp, setShowPopUp] = useState(false)
    const [bloqPopUp, setBloqPopUp] = useState(false)

    const closePopUp = () => {
        setShowPopUp(false)
        setBloqPopUp(true)
    }

    document.onmouseleave = () => {
        if (!bloqPopUp) setShowPopUp(true)
        setBloqPopUp(true)
    }

    useEffect(() => {

        let date = dueDay.split('/')
        let hour = dueTime.split(':')
        const dueDate = new Date(`${date[1]} ${date[0]}, ${date[2]} ${hour[0]}:${hour[1]}:00 GMT-03:00`).valueOf()


        if (dueDate - new Date().valueOf() > 0) {
            setTimeout(() => {
                setBloqPopUp(bloq => {
                    if (!bloq) setShowPopUp(true)
                    return true
                })
            }, launchSeconds * 1000)
        } else {
            setBloqPopUp(true)
        }
    }, [launchSeconds, dueDay, dueTime])

    return (
        showPopUp ?
            <Section>
                <Container>
                    <BlackFridayBanner></BlackFridayBanner>
                    {/* <SubTitle>
                        <div className="grad-orange" >O PREÇO VAI AUMENTAR</div>
                        <small>de R$593,40 para R$793,00!</small><br />
                        <div style={{
                            fontSize: "1.5rem",
                            fontWeight: 600
                        }}>APROVEITE ENQUANTO AINDA DÁ TEMPO</div>
                    </SubTitle> */}
                    <TextPopupTitle>
                        <h1>CURSO DEV WEB</h1><h1>  FULL STACK</h1>
                    </TextPopupTitle>
                    {/* <FeaturePopup>
                        20% OFF
                    </FeaturePopup> */}
                    <TextPopup>
                        Quando o timer chegar ao fim as inscrições vão se encerrar.
                    </TextPopup>



                    <ContainerTime>
                        <Timer dueDay={dueDay} dueTime={dueTime}></Timer>
                    </ContainerTime>

                    <ContainerButtons>
                        <button onClick={closePopUp}>VOU PERDER A OPORTUNIDADE</button>
                        <LinkPay src="popup">
                            SIM, EU QUERO APRENDER!
                        </LinkPay>
                    </ContainerButtons>
                </Container>
            </Section > : <></>
    )
}


const TextPopupTitle = styled.h1`
color: white;
text-align: center;
font-family: 'Archivo Black', sans-serif;
line-height: 48px;
font-size: 34px;

`
const TextPopup = styled.div`
color: white;
max-width: 480px;
text-align: center;
font-size:20px;

@media only screen and (max-width: 670px) {
    font-size:16px;

}
`
const FeaturePopup = styled.div`
background: var(--grad-orange);
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
background-clip: text;
font-size:45px;
font-family: 'Archivo Black', sans-serif;

`



export default TimerPopup
