import React from 'react'
import styled from 'styled-components'

export default function index() {
    return (
        <Banner>
            ÚLTIMA CHANCE
        </Banner>
    )
}

const Banner = styled.div`
{
    background: var(--grad-orange);
    padding: 10px;
    width:100%;
    text-align: center;
    font-size: 30px;
    font-weight: normal;
    font-family: 'Archivo Black', sans-serif;
}


`