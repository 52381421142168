import { useState } from 'react'

import { ContainerBlue, Content, HeroContainer } from './styles'
import Header from '../../../components/Header'
import ButtonPulseOrange from '../../../components/ButtonPulseOrange'
import ModalVideo from './components/ModalVideo'

import arrowRight from '../../../../assets/icons/arrow-right.svg'
import heroImage from '../../../../assets/images/hero-image.png'
import slash from '../../../../assets/icons/slash.svg'
// import IncreasePriceBanner from '../../../components/IncreasePriceBanner.js/IncreasePriceBanner'
// import Banner from '../../../components/Banner'
function HeroSection({ id }) {
  const [modalVideo, setModalVideo] = useState(false)

  const toggleModalVideo = () => setModalVideo(oldValue => !oldValue)

  return (
    <ContainerBlue id={id}>
      <Content>
        <Header />
        {/* <IncreasePriceBanner></IncreasePriceBanner> */}
        <HeroContainer>
          <div>
            <h1>CURSO DESENVOLVIMENTO WEB FULL STACK</h1>

            <p>
              Vá do zero até seus primeiros projetos como programador profissional
            </p>

            {/* <ButtonPulseOrange
              boxShadowHover='var(--box-shadow-orange-dark)'
              onClick={toggleModalVideo}
            >
              <img src={arrowRight} alt='ArrowRight' />
              ASSISTA O VÍDEO
            </ButtonPulseOrange> */}
          </div>

          <img src={heroImage} alt='hero' />

          <div className='slashContainer'>
            <img src={slash} alt='slash' className='slash' />
          </div>
        </HeroContainer>

        {modalVideo && <ModalVideo closeModal={toggleModalVideo} />}
      </Content>
    </ContainerBlue>
  )
}

export default HeroSection
