import styled from 'styled-components'

import CardScale from '../CardScale'

const CardStudent = styled(CardScale)`
  padding: 40px 30px;
  max-width: 550px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .avatar {
    border-radius: 50%;
    width: 100px;
  }

  @media(max-width: 900px) {
    padding: 30px;
  }
`

export default CardStudent
