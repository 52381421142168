import { Container } from './styles'
import SlashTitle from '../../../components/SlashTitle'
import GridStudents from '../../../components/GridStudents'
import ButtonSubscribe from '../../../components/ButtonSubscribe'

import arrayStudents from './arrayStudents'

function JobTestimonialsSection({ id }) {
  return (
    <Container id={id}>
      <SlashTitle>
        INSCREVA-SE NO CURSO QUE ESTÁ MUDANDO A VIDA DOS SEUS ALUNOS
      </SlashTitle>

      <GridStudents columns='2' arrayStudents={arrayStudents} />

      <ButtonSubscribe />
    </Container>
  )
}

export default JobTestimonialsSection
