import styled from 'styled-components'

export const Container = styled.section`
  padding: 30px;

  .content {
    width: 100%;
    max-width: var(--max-width);
    padding: 60px 0;
    background: var(--background);
    padding: 45px;

    display: flex;
    align-items: center;

    img {
      height: 200px;
    }

    div {
      margin-left: 60px;
    }
  }

  @media (max-width: 900px) {
    .content {
      padding: 30px;
      flex-direction: column;

      div {
        margin: 30px 0 0;
      }
    }
  }

  @media (max-width: 520px) {
    padding: 30px 15px;
  }
`
