import { Container } from './styles'
import { Title } from '../Texts'

import slash from '../../../assets/icons/slash.svg'

function SlashTitle({ children }) {
  return (
    <Container>
      <img src={slash} alt='slash' />
      <Title textAlign='left'>{children}</Title>
    </Container>
  )
}

export default SlashTitle
