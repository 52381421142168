import React from 'react'
import './waitSection.css'
import heroImage from '../../../assets/images/hero-image.png'
import LeadForm from './LeadForm'
import Header from './Header'
export default function NewLeadCard() {
    return (
        <div className='cdwsection cdwsectionblue flex justify-between mt-9'>
            <div className='flex flex-col justify-between '>
                <Header></Header>

                <div>
                    <h1 className=' my-4 text-4xl lg:text-5xl  text-slate-200  font-bold'>
                        CURSO DEV WEB<br></br> FULL STACK
                    </h1>
                    <div className='text-slate-400 max-w-fit'>Neste momento o Curso Dev Web Programador BR não está aceitando novos alunos. Se você quiser ser um dos primeiros a saber quando esse curso, ou qualquer outro, estiver disponível novamente, deixe seu email aqui embaixo.</div>

                </div>
                <div className='mt-1  '>
                    <LeadForm></LeadForm>
                    <div className='text-sm text-slate-500'>
                        *programadorbr.com precisa do e-mail que você fornece para entrar em contato sobre nossos produtos e serviços. Você pode cancelar essas comunicações a qualquer momento. Consulte nossa política de privacidade para os termos e condições e para entender como protegemos seus dados.
                    </div>
                </div>
            </div>
            <div className='hidden lg:inline-block'>
                <img className='heroImg' src={heroImage} alt='hero' />

            </div>

        </div>
    )
}
