import styled from 'styled-components'

export const Container = styled.div`
  margin: 60px 0;
  display: grid;
  justify-items: center;
  grid-template-columns: ${({ columns = '1' }) => `repeat(${columns}, 1fr)`};
  gap: 50px 40px;

  @media (max-width: 900px) {
    margin: 48px 0;
    grid-template-columns: 1fr;
    gap: 30px 20px;
  }
`
