import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  max-width: var(--max-width);
  padding: 60px 0 120px;
  display: flex;

  .textsContainer {
    max-width: 360px;

    p {
      color: var(--text-gray-light);
      margin: 25px 0;
    }
  }

  .TitleAndDescriptionBrackets, .ButtonSubscribeFooter {
    display: none;
  }

  .pricesContainer {
    width: 100%;
    margin-left: 60px;
  }

  .priceTotalContainer {
    padding: 35px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .grad {
      font-size: 36px;
      font-weight: 800;

      background: var(--grad-orange);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
    .grad-orange {
      position:relative;
      bottom:0.2rem;
      background: var(--grad-orange);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
    .grad-blue {
      position:relative;
      bottom:0.2rem;
      background: var(--grad-blue-light);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
    .increase{
      font-weight: 800;

      font-size:1.5rem;
    }
    .priceTotal {
      display: flex;

      .plots {
        margin-top: 10px;
      }

      .plots, .interestFree {
        color: var(--text-gray);
        font-size: 16px;
        line-height: 24px;
      }

      .value {
        margin: 0 10px;
        color: var(--text-dark);
        font-size: 64px;
        line-height: 70px;
        font-weight: 800;
      }

      .cents {
        margin-top: 10px;
        color: var(--text-dark);
        font-size: 22px;
        line-height: 34px;
        font-weight: 800;
      }

   

      div {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
      }
    }
  }

  @media (max-width: 1160px) {
    flex-direction: column;
    padding: 60px 30px 120px;

    .textsContainer {
      display: none;
    }

    .TitleAndDescriptionBrackets, .ButtonSubscribeFooter {
      display: flex;
    }

    .pricesContainer {
      margin: 60px 0 0;

      .priceTotalContainer {
        margin-bottom: 48px;
      }
    }
  }

  @media (max-width: 900px) {

  }

  @media (max-width: 520px) {
    padding: 48px 15px 60px;

    .priceTotalContainer {
      padding: 30px 10px;
      flex-direction: column;

      .grad {
        text-align: center;
        font-size: 22px;
      }
    }
  }
`
