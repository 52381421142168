import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  img {
    height: 250px;
    position: absolute;
    top: 10px;
    left: -30px;
  }

  h2 {
    max-width: 870px;
    margin-left: 120px;
  }

  @media (max-width: 1160px) {
    img {
      left: 0;
    }

    h2 {
      margin-left: 120px;
    }
  }

  @media (max-width: 900px) {
    img {
      top: -20px;
      height: 200px;
    }

    h2 {
      margin-left: 60px;
    }
  }

  @media (max-width: 520px) {
    img {
      top: -5px;
      left: -16px;
      height: 150px;
    }

    h2 {
      margin-left: 36px;
    }
  }
`
