import { Container } from './styles'
import TitleAndDescriptionBrackets from '../../../components/TitleAndDescriptionBrackets'
import ButtonSubscribe from '../../../components/ButtonSubscribe'
import GridStudents from '../../../components/GridStudents'

import arrayStudents from './arrayStudents'

function DidacticTestemonialsSection({ id }) {
  return (
    <Container id={id}>
      <TitleAndDescriptionBrackets
        title='FOCO NO INICIANTE'
        description='DIDÁTICA DIFERENCIADA E SUPORTE PERSONALIZADO PARA QUEM NUNCA ESCREVEU UMA LINHA DE CÓDIGO'
      />

      <GridStudents
        columns='3'
        arrayStudents={arrayStudents}
        className='grid'
      />

      <ButtonSubscribe />
    </Container>
  )
}

export default DidacticTestemonialsSection
