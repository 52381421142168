import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  position: relative;
  width: 90%;
  max-width: 1160px;
  height: 100%;
  max-height: 650px;
  margin-top: 5%;

  iframe {
    width: 100%;
    height: 100%;
    box-shadow: 3px 4px 100px 40px rgba(0,0,0,0.65);
  }

  button {
    border-radius: 4px;
    padding: 2px;
    background: var(--grad-blue);
    fill: #fff;
    color: #fff;
    opacity: 0.6;
    transition: opacity 0.2s;

    position: absolute;
    top: 16px;
    right: 16px;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 1280px) {
    max-height: 50vw;
    margin-top: 10%;
  }

  @media (max-width: 600px) {
    margin-top: 50%;
  }
`
