import React from 'react'
import './waitSection.css'
import heroImage from '../../../assets/images/UIUX-hero.png'
import uiuxLogo from '../../../assets/images/UIUX-Logo.png'

import LeadForm from './LeadForm'
export default function UIUXDesignProCard() {
    return (
        <div className='cdwsection cdwsectionbdark flex justify-between mt-11 max-h-uiux overflow-clip'>
            <div className='flex flex-col md:items-start '>
                <div >
                    <img className='   ' src={uiuxLogo} alt='uiux logo' />

                    <h3 className=' my-4 text-2xl lg:text-3xl  text-slate-200  font-bold'>
                        Quer aprender a criar suas próprias interfaces do zero?                    </h3>
                    <div className='text-gray-400 max-w-fit'>
                        Então dá uma olhada no curso de UI UX Design do meu sócio André Saad. Ele ensina a fazer projetos de sites e apps do zero até a entrega para o dev usando o Figma. Eu fiz e recomendo demais para quem quer evoluir como dev front end.
                    </div>

                </div>
                <a href='https://uiuxbr.design/eq68' className='p-3 my-2 mt-4 border-2 border-gray-700 text-slate-50  px-4  rounded-xl text-center'>Quero saber mais detalhes</a>

            </div>
            <img className='heroImg hidden lg:inline   ' src={heroImage} alt='hero' />


        </div>
    )
}
