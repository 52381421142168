import { useLocation } from 'react-router'
import styled, { css } from 'styled-components'

function LinkPay({ children, center, src }) {
  // const query = new URLSearchParams(location.search);
  const location = useLocation()

  return (
    <A href={`https://programadorbr.com/pay${location.search}${src ? location.search ? '&src=bfpopup' : '?src=bfpopup' : ''}`} center={center}>
      {children}
    </A>
  )
}

const A = styled.a`
  ${({ center }) => center && css`
    display: block;
    text-align: center;
  `}
`

export default LinkPay
