import React, { useEffect, useState } from 'react'
import Mouse from './Mouse'
import { firebaseApp } from './firebaseApp'
import { getDatabase, ref, onValue, push, set, remove, onChildChanged } from "firebase/database";


function Pointers() {
  const db = getDatabase(firebaseApp);

  const [mouseREF, setMouseREF] = useState()
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const [otherMice, setOtherMice] = useState({})
  const [mouseColor, setMouseColor] = useState()

  useEffect(() => {
    const miceRef = ref(db, 'mice');
    onChildChanged(miceRef, (data) => {
      setOtherMice(prev => {
        const mouse = { ...prev }
        mouse[data.key] = data.val()

        console.log(data.key, data.val())
        return mouse
      })
    });



  }, [])

  useEffect(() => {
    console.log(otherMice)
  }, [otherMice])




  useEffect(() => {
    let mouse = JSON.parse(localStorage.getItem("mouse"))
    let mouseRef = null
    let mouseidkey = null
    if (!mouse) {
      const miceRef = ref(db, 'mice');
      mouseRef = push(miceRef)
      mouseidkey = mouseRef.key
      console.log(mouseidkey)
      console.log("mid", mouseidkey)
      var randomColor = Math.floor(Math.random() * 16777215).toString(16);
      localStorage.setItem("mouse", JSON.stringify({ mouseidkey, color: randomColor }))
      setMouseColor(randomColor)

    } else {
      let mouseidkey = mouse.mouseidkey
      setMouseColor(mouse.color)

      mouseRef = ref(db, 'mice/' + mouseidkey)
    }
    setMouseREF(mouseRef)



  }, [])

  useEffect(() => {
    if (mouseREF)
      window.onmousemove = (e) => {

        // console.log(e.clientX, e.clientY)
        setMousePosition({ x: e.pageX, y: e.pageY })
      }

    return () => {
      if (mouseREF)
        remove(mouseREF)
    }
  }, [mouseREF])

  useEffect(() => {
    if (mouseREF && mouseColor) {
      set(mouseREF, {
        x: mousePosition.x,
        y: mousePosition.y,
        color: mouseColor
      })
    }
  }, [mouseREF, mousePosition, mouseColor])



  return (
    < >
      {Object.keys(otherMice).map(key => {
        if (mouseREF && key != mouseREF.key)
          return <Mouse key={key} top={otherMice[key].y} left={otherMice[key].x} color={otherMice[key].color}></Mouse>


      })}
    </>
  )
}

export default Pointers