
import styled from 'styled-components'

export const Section = styled.section`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);

    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;


`

export const Container = styled.main`
    max-width: 764px;
    height: 518px;
    padding: 15px;
    margin: 0 24px;
    background: black;
    border: 2px solid #3E4550;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;


    h1 {
        text-transform: uppercase;
    }

    @media only screen and (max-width: 670px) {
        padding: 16px;
        height: 85%;

        h1 {
            font-size: 32px;
            line-height: 40px;
        }
    }

    @media only screen and (max-width: 400px) {
        height: 80%;

        h1 {
            font-size: 28px;
            line-height: 36px;
        }
    }
`

export const ContainerTime = styled.div`
    display: flex;
    // color: #111720;
    color: white;
    margin-bottom: 20px;
    margin-top: 20px;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h2 {
        font-weight: bold;
        font-size: 48px;
        line-height: 72px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
    }

    h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.02em;
    }

    hr {
        width: 2px;
        // height: 100%;
        border: none;
        border-radius: 1px;
        opacity: .6;
        background: var(--grad-orange);
        margin: 0 24px;
    }

    @media only screen and (max-width: 670px) {
        h2 {
            font-size: 10vw;
            line-height: 12vw;
        }

        h3 {
            font-size: 16px;
            line-height: 16px;
        }

        hr {
            margin: 0 3vw;
        }
    }

    @media only screen and (max-width: 400px) {
        h2 {
            font-size: 28px;
            line-height: 30px;
        }

        h3 {
            font-size: 12px;
            line-height: 12px;
        }
    }
`

export const ContainerButtons = styled.div`
    display: flex;
    color: white;

    button {
        // border: 2px solid var(--progbr-color);
        border: 2px solid gray;

        color: var(--color-text-light);
        background: none;
    }

    a {
        background: var(--grad-orange);
        margin-left: 28px;
        color: #FFFFFF;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    button, a {
        flex-grow: 1;
        height: 64px;
        padding: 0 24px;
        font-size: 16px;
        font-weight: 800;
        line-height: 16px;
        outline: none;
        cursor: pointer;
        border-radius: var(--border-radius);
        transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);

        :hover {
            transform: ${({ transform = 'scale(1.07)' }) => transform};
        }
    }

    @media only screen and (max-width: 670px) {
        flex-direction: column;
        
        a {
            margin: 24px 0 0;
        }
    }

    @media only screen and (max-width: 400px) {
        a {
            margin: 16px 0 0;
        }

        button, a {
            height: 56px;
            font-size: 14px;
        }
    }
`