import { ContainerBlue, Content } from './styles'
import { Title } from '../../../components/Texts'
import ButtonSubscribe from '../../../components/ButtonSubscribe'

import slash from '../../../../assets/icons/slash.svg'

function LastCallSection({ id }) {
  return (
    <ContainerBlue id={id}>
      <Content>
        <img className='slash' src={slash} alt='slash' />

        <Title>
          PRONTO PARA SE TORNAR UM PROGRAMADOR PROFISSIONAL?
        </Title>

        <ButtonSubscribe />
      </Content>
    </ContainerBlue>
  )
}

export default LastCallSection
