import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    --max-width: 1160px;
    --border-radius: 8px;

    --background: #fff;
    --background-gray: #f9fbfc;

    --text-white: #fff;
    --text-dark: #0e1c34;
    --text-gray-light: #b1afb9;
    --text-gray: #6d7278;

    --progbr-color: #1F95FD;
    --grad-blue-medium: linear-gradient(225deg, #29678B 0%, #0E1C34 100%);
    --grad-blue: linear-gradient(225deg, rgb(30, 79, 108) 0%, rgb(14, 28, 52) 100%);
    --grad-blue-light: linear-gradient(29.83deg, #1F95FD 0%, #4FE8E4 100%);
    --grad-orange: linear-gradient(225deg, #FFAF00 0%, #FE4D1B 48.15%, #E70154 100%);

    --box-shadow-card: 10px 10px 25px 1px #C0C5CE;
    --box-shadow-blue: 0 18px 40px 0 rgba(79,232,228,0.5);
    --box-shadow-orange: 0 18px 40px 0 rgba(252,71,31,0.3);
    --box-shadow-orange-dark: 0 20px 60px 10px rgba(252,71,31,0.6);
  }


  * {
    margin: 0;
    padding: 0;
    letter-spacing: 0px;
    box-sizing: border-box;
    color: var(--color-title);
    // font-family: Eurostile, sans-serif;
      font-family: "Inter", sans-serif;

    text-decoration: none;
    vertical-align: middle;
  }

  #root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-title);
    background: var(--background-gray);
  }

  html{
    scroll-behavior: smooth;
  }

  button {
    cursor: pointer;
    border: none;
  }

  a {
    color: inherit;
  }

  .grad-orange {
    background: var(--grad-orange);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
`

export default GlobalStyle
