import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  max-width: var(--max-width);
  padding: 60px 0 120px;

  h3 {
    margin-bottom: 45px;
  }

  button {
    display: block;
    margin: 60px auto 0;
    background: #fff;
    padding: 18px 30px;
    border: 1px solid #0045FF;
    border-radius: var(--border-radius);

    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
  }

  @media (max-width: 1160px) {
    padding: 60px 30px 120px;
	}

	@media (max-width: 900px) {
    padding: 48px 30px 60px;
	}

	@media (max-width: 520px) {
    padding: 48px 15px 60px;
	}
`
