import { ContainerBlue, Content } from './styles'
import { Text, Title } from '../../../components/Texts'

import detail from '../../../../assets/images/detail-igor-section.png'
import igorImg from '../../../../assets/images/quem-Igor.png'

function IgorOliveiraSection({ id }) {
  return (
    <ContainerBlue id={id}>
      <Content>
        <img src={detail} alt='detail' className='detailImg' />

        <div className='textsContainer'>
          <Title>QUEM É IGOR OLIVEIRA?</Title>

          <Text color='var(--text-gray-light)'>
            Programador, Empreendedor e Criador de conteúdo com mais de 2.5 Milhões de views no
            Youtube. Um cara apaixonado por tecnologia e empreendedorismo. Seu maior objetivo é fazer
            com que mais pessoas vejam programação como a melhor opção de carreira que existe,
            e entendam que o código não é a única coisa que um programador deve saber para ter
            destaque na profissão.
          </Text>
        </div>

        <img src={igorImg} alt='igor oliveira' className='igorImg' />
      </Content>
    </ContainerBlue>
  )
}

export default IgorOliveiraSection
