import styled, { css } from 'styled-components'
import CardScale from '../../../../components/CardScale'
import { TextMedium } from '../../../../components/Texts'

import arrowDown from '../../../../../assets/icons/arrow-down.png'

function CardFaq({ question, response, selected, onClick }) {
   return (
    <Accordion selected={selected} onClick={onClick}>
      <Card selected={selected}>
        <TextMedium bold uppercase className='question'>
          {question}
        </TextMedium>

        <img src={arrowDown} alt='arrow' className='arrow' />
      </Card>

      <div className={selected ? 'responseContainer active' : 'responseContainer'}>
        <TextMedium className='response'>{response}</TextMedium>
      </div>
    </Accordion>
  )
}

const Accordion = styled.div`
  margin-bottom: 18px;

  .responseContainer {
    padding: 0px 30px;
		margin-top: 0px;
		margin-bottom: 0px;
		max-height: 0;
    opacity: 0;
		overflow: hidden;
		transition:
      max-height 0.2s ease-out,
      margin-top 0.2s ease-out,
      margin-bottom 0.2s ease-out,
      opacity 0.3s ease-out;
  }

  .active {
    max-height: initial;
    margin: 20px 0 40px;
    opacity: 1;
  }

  @media (max-width: 520px) {
    .responseContainer {
      padding: 0px 15px;
    }
  }
`

const Card = styled(CardScale)`
  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow {
    cursor: pointer;
    margin-left: 32px;
    ${({ selected }) => selected && 'transform: rotate(180deg);'};
    transition: transform .8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  ${({ selected }) => selected && css`
    box-shadow: var(--box-shadow-card);
    transform: scale(1.01);
  `}

  @media (max-width: 1160px) {
    ${({ selected }) => selected && css`
      .question {
        background: var(--grad-orange);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    `}
  }

  @media (max-width: 520px) {
    padding: 30px 14px;
  }
`

export default CardFaq
