// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDT3uGDuKf0Lu3ntaGBwT2TMDheZlUAihk",
    authDomain: "progbrwebsite.firebaseapp.com",
    databaseURL: "https://progbrwebsite.firebaseio.com",
    projectId: "progbrwebsite",
    storageBucket: "progbrwebsite.appspot.com",
    messagingSenderId: "492324982328",
    appId: "1:492324982328:web:2182f2d360429d68a460cd"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

