import { Link } from 'react-router-dom'

import { Container, Content } from './styles'
import Header from '../../../components/Header'
import { TextSmall } from '../../../components/Texts'

function FooterSection({ id }) {
  return (
    <div className='flex flex-col-reverse lg:flex-row justify-between p-4'>
      {/* <Header btnPay={false} /> */}
      <span className='cnpj text-gray-400'>
        {/* Iplo Tecnologia da Informação Ltda. CNPJ: 11.509.788/0001-82 */}
        Copyright © 2024 Iplodev Tech Inc. All rights reserved.
      </span>
      <Link to='politicas'>
        <TextSmall className='textPrivacy' color='var(--text-gray-light)'>
          Termos de Uso e Política de Privacidade
        </TextSmall>
      </Link>
    </div>


  )
}

export default FooterSection
