import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  background: var(--background);
  display: flex;
  justify-content: center;
`

export const Content = styled.section`
  width: 100%;
  max-width: var(--max-width);
  padding: 120px 0 60px;
  position: relative;

  .grid {
    margin-bottom: 60px;
  }

  @media (max-width: 1160px) {
    padding: 120px 30px 60px;

    .grid {
      grid-template-columns: 1fr 1fr;

      .gridItem {
        padding: 0 60px;
        align-items: flex-start;
        flex-direction: column;

        .title {
          margin: 10px 0 4px;
        }

        img {
          margin-left: 0;
        }

        div {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 900px) {
    padding: 60px 30px 60px;

    .grid {
      grid-template-columns: 1fr;
      margin-bottom: 48px;
    }
  }

  @media (max-width: 520px) {
    padding: 60px 15px 48px;

    .grid {
      .gridItem {
        padding: 0 48px;
      }
    }
  }
`
