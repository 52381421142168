import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    width: 100%;
    height: 500px;
    padding: 0 24px;
    background: var(--grad-blue);
    display: flex;
    justify-content: center;

    section {
      max-width: var(--max-width);
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        padding: 24px;
      }

      div {
        max-width: 550px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align:center;
      }
    }
  }

  main {
    padding: 60px 12px;
    max-width: var(--max-width);

    section {
      padding: 1px 0;
    }
  }

  footer {
    width: 100%;
    padding: 24px;
    background: var(--grad-blue);
    display: flex;
    justify-content: center;
  }

  h1 {
    font-size: 64px;
    font-weight: 800;
    line-height: 70px;
    margin-bottom: 24px;
    color: var(--text-white);
  }

  span {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    color: var(--text-gray-light);
  }

  h2 {
    font-size: 36px;
    font-weight: 800;
    line-height: 52px;
    margin-bottom: 24px;
    color: var(--text-dark);
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    margin: 24px 0;
    color: var(--text-gray);
  }

  h3 {
    font-size: 22px;
    font-weight: 800;
    line-height: 34px;
    margin: 24px 0;
    color: var(--text-dark);
  }

  h4 {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 24px;
    color: var(--text-gray);
    margin: 24px 0;
  }

  @media only screen and (max-width: 900px) {
    h1 {
      font-size: 46px;
    }

    h2 {
      font-size: 32px;
    }

    h4 {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 520px) {
    header {
      height: 400px;
    }

    h1 {
      font-size: 32px;
      line-height: 40px;
    }

    span {
      font-size: 18px;
      line-height: 26px;
    }

    h2 {
      font-size: 22px;
      line-height: 40px;
    }

    h4 {
      font-size: 16px;
    }
  }
`

export default Container
