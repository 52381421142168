import styled from 'styled-components'

import CardScale from '../../../../components/CardScale'
import { Text } from '../../../../components/Texts'

function CardPrice({ image, description, price }) {
  return (
    <Card>
      <div>
        <img src={image} alt='price icon' />
        <Text className='description' bold>{description}</Text>
      </div>

      <Text className='price' bold>{price}</Text>
    </Card>
  )
}

const Card = styled(CardScale)`
  padding: 30px;
  margin-bottom: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    img {
      width: 40px;
    }
  }

  p {
    font-size: 22px;

    &.description {
      margin-left: 25px;
    }

    &.price {
      white-space: nowrap;
    }
  }

  @media (max-width: 900px) {
    padding: 25px 30px;
  }

  @media (max-width: 520px) {
    padding: 25px 10px;

    p.description {
      font-size: 16px;
      line-height: 22px;
      margin-left: 10px;
    }
  }
`

export default CardPrice
