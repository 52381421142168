import { Link } from 'react-router-dom'

import { Container, Content } from './styles'
import Header from '../../../components/Header'
import { TextSmall } from '../../../components/Texts'

function FooterSection({ id }) {
  return (
    <Container id={id}>
      <Content>
        <Header btnPay={false} />

        <Link to='politicas'>
          <TextSmall className='textPrivacy' color='var(--text-gray-light)'>
            Termos de Uso e Política de Privacidade
          </TextSmall>
        </Link>
      </Content>

      <span className='cnpj'>
        {/* Iplo Tecnologia da Informação Ltda. CNPJ: 11.509.788/0001-82 */}
        Copyright © 2022 IPLODEV TECH INC. All rights reserved.
      </span>
    </Container>
  )
}

export default FooterSection
