import HeroSection from './HeroSection'
import BigNumbersSection from './BigNumbersSection'
import JobTestimonialsSection from './JobTestimonialsSection'
import CourseContentSection from './CourseContentSection'
import ContentTestimonialsSection from './ContentTestimonialsSection'
import WarrantySection from './WarrantySection'
import DidacticTestemonialsSection from './DidacticTestemonialsSection'
import CoursePriceSection from './CoursePriceSection'
import BenefitsSection from './BenefitsSection'
import IgorOliveiraSection from './IgorOliveiraSection'
import FaqSection from './FaqSection'
import LastCallSection from './LastCallSection'
import FooterSection from './FooterSection'
import Header from '../../components/Header'
import Banner from '../../components/BlackFridayBanner'
import TimerBanner from '../../components/TimerBanner'
import Pointers from '../../Mouse/Pointers'
import TimerPopup from '../../components/TimerPopup.js'


function Home() {
  const pop_up_vendas = {
    dueDay: '15/april/2024',
    dueTime: '23:59:00',
    launchSeconds: 50
  }

  return (
    <>
      {/* <Header></Header> */}

      {/* <Banner></Banner> */}

      <HeroSection id='HeroSection' />

      <TimerBanner {...pop_up_vendas}></TimerBanner>


      <BigNumbersSection id='BigNumbersSection' />

      <JobTestimonialsSection id='testimonials' />

      <CourseContentSection id='course-content' />

      <ContentTestimonialsSection id='ContentTestimonialsSection' />

      <WarrantySection id='WarrantySection' />

      <DidacticTestemonialsSection id='DidacticTestemonialsSection' />

      <CoursePriceSection id='CoursePriceSection' />

      <TimerBanner {...pop_up_vendas}></TimerBanner>

      <BenefitsSection id='benefits' />

      <IgorOliveiraSection id='igor' />

      <FaqSection id='faq' />

      <LastCallSection id='LastCallSection' />

      <FooterSection id='FooterSection' />

      <TimerPopup {...pop_up_vendas} />
      {/* <Pointers></Pointers> */}
    </>
  )
}

// const formatNumber = number => (`0${number}`).slice(-2)

// function getTimeUntil(dateString, hourString) {
//   let date = dateString.split('/')
//   let hour = hourString.split(':')

//   return new Date(`${date[1]} ${date[0]}, ${date[2]} ${hour[0]}:${hour[1]}:00 GMT-03:00`).valueOf() - new Date().valueOf()
// }

// function Countdown(dateString, hourString) {


//   const time = getTimeUntil(dateString, hourString)
//   if (time < 0) {
//     return { timeInDays: 0, timeInHours: 0, timeInMinutes: 0, timeInSeconds: 0 }
//   }
//   let timeInSeconds = Math.floor(time / 1000)
//   let timeInMinutes = Math.floor(timeInSeconds / 60)
//   let timeInHours = Math.floor(timeInMinutes / 60)
//   let timeInDays = Math.floor(timeInHours / 24)




//   timeInDays = formatNumber(timeInDays)
//   timeInHours = formatNumber(timeInHours % 24)
//   timeInMinutes = formatNumber(timeInMinutes % 60)
//   timeInSeconds = formatNumber(timeInSeconds % 60)

//   return { timeInDays, timeInHours, timeInMinutes, timeInSeconds }
// }

export default Home
