import React from 'react'
import logo from '../../../assets/logo.svg'
import Container from '../Privacy/style'

export default function Header() {
    return (
        <div className='flex justify-start p-4'>
            <img src={logo} alt='logo progbr' />

        </div>

    )
}
