import { Container } from './styles'
import { Text, Title } from '../../../components/Texts'
import TitleAndDescriptionBrackets from '../../../components/TitleAndDescriptionBrackets'
import ButtonSubscribe from '../../../components/ButtonSubscribe'
import CardPrice from './components/CardPrice'

import Pizza from '../../../../assets/icons/coursePrice/Pizza.svg'
import Beer from '../../../../assets/icons/coursePrice/Beer.svg'
import Steak from '../../../../assets/icons/coursePrice/Steak.svg'
import Dinner from '../../../../assets/icons/coursePrice/Dinner.svg'

function CoursePriceSection({ id }) {
  return (
    <Container id={id}>
      <div className='textsContainer'>
        <Title textAlign='left'>PRIORIZE SUA CARREIRA</Title>

        <Text>
          Investir no seu futuro profissional vai sair bem mais barato do que você imagina.
        </Text>

        <ButtonSubscribe center={false} />
      </div>

      <TitleAndDescriptionBrackets
        title='PRIORIZE SUA CARREIRA'
        description='Investir no seu futuro profissional vai sair bem mais barato do que você imagina.'
        className='TitleAndDescriptionBrackets'
      />

      <div className='pricesContainer'>
        <CardPrice
          image={Pizza}
          description={`COMBO DE PIZZA DOMINO'S`}
          price='R$ 61,00'
        />

        <CardPrice
          image={Beer}
          description='CHOPP DO FIM DE SEMANA'
          price='R$ 80,00'
        />

        <CardPrice
          image={Steak}
          description='CHURRASCO PRA ALEGRAR A FAMÍLIA'
          price='R$ 100,00'
        />

        <CardPrice
          image={Dinner}
          description='JANTARZINHO COM O DATE'
          price='R$ 120,00'
        />

        <div className='priceTotalContainer'>
          <div>
            {/* <div className="increase">SÓ NA BLACK FRIDAY  </div> */}

            <span className='increase'>
              INVESTIR NO SEU FUTURO
            </span>
            {/* <div className="increase">DE <span className="grad-orange">R$ 792,96</span> POR <span className="grad-blue">R$ 634,37</span> </div> */}
            {/* <div className="increase">
              PREÇO VÁLIDO SÓ ATÉ O DIA 14 DE NOVEMBRO, DOMINGO. <br />
            </div> */}
          </div>
          <div className='priceTotal'>
            <span className='plots'>12x</span>
            <span className='value'>66</span>
            {/* <span className='value grad-blue'>52</span> */}

            <div>
              <span className='cents'>,08</span>
              {/* <span className='cents grad-blue'>,87</span> */}

              <span className='interestFree'>Sem juros</span>
            </div>
          </div>
        </div>
      </div>

      <ButtonSubscribe className='ButtonSubscribeFooter' />
    </Container >
  )
}

export default CoursePriceSection
