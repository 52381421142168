import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  background: var(--background);
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: var(--max-width);
  padding: 60px 0;

  h2 {
    max-width: 550px;
    margin-left: 60px;
  }

  .titleBrackets {
    display: flex;
    align-items: center;
    margin: 60px 0 0 60px;

    img {
      height: 70px;
    }

    h3 {
      margin-left: 2px;
    }
  }

  .cardElectron {
    background: var(--background-gray);
    padding: 45px;
    margin: 60px 0;

    display: flex;
    align-items: center;

    img {
      height: 100px;
    }

    div {
      margin-left: 60px;
    }
  }

  @media(max-width: 1160px) {
    padding: 60px 30px;
  }

  @media (max-width: 900px) {
    padding: 48px 30px;

    h2 {
      margin-left: 48px;
    }

    .titleBrackets {
      margin: 48px 0 0 48px;

      img {
        height: 60px;
      }
    }

    .cardElectron {
      padding: 30px;
      margin: 48px 0;
      flex-direction: column;

      div {
        margin: 30px 0 0;
      }
    }
  }

  @media (max-width: 520px) {
    padding: 48px 15px;

    h2 {
      margin-left: 0;
    }

    .titleBrackets {
      margin: 48px 0 0;

      img {
        height: 54px;
      }
    }
  }
`
