import styled from 'styled-components'

import CardScale from '../../../components/CardScale'
import plusIcon from '../../../../assets/icons/plus.svg'
import { Title } from '../../../components/Texts'

function CardBigNumber({ number, description, plus }) {
  return (
    <Card>
      {plus && (
        <div className="width-70">
          <img src={plusIcon} alt="plus" />
        </div>
      )}

      <div className="container-numbers">
        <Title>{number}</Title>
        <p>{description}</p>
      </div>

      {plus && <div className="width-70" />}
    </Card>
  )
}

const Card = styled(CardScale)`
  max-width: 360px;
  padding: 40px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 24px;
    line-height: 34px;
    font-weight: 800;
    color: var(--text-gray);
    text-align: center;
  }

  .container-numbers {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .width-70 {
    width: 70px;

    img {
      margin-left: 20px;
    }
  }

  @media(max-width: 1160px) {
    & + div {
      margin: 30px 0 0;
    }
  }

  @media(max-width: 520px) {
    padding: 30px;

    & + div {
      margin: 24px 0 0;
    }

    p {
      font-size: 22px;
    }

    .width-70 {
      img {
        margin-left: 0;
      }
    }
  }
`

export default CardBigNumber
