import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  max-width: var(--max-width);
  padding: 60px 0;

  @media (max-width: 1160px) {
    padding: 60px 30px;

    .grid {
      grid-template-columns: 1fr 1fr;

      div:nth-child(9) {
        display: none;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 48px 30px;

    .grid {
      grid-template-columns: 1fr;

      div:nth-child(9) {
        display: block;
      }
    }
  }

  @media (max-width: 520px) {
    padding: 48px 15px;
  }
`
