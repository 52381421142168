import styled from 'styled-components'

function ButtonPulseOrange({ children, boxShadowHover, ...rest }) {
  return (
    <Button boxShadowHover={boxShadowHover} {...rest}>
      {children}
    </Button>
  )
}

const Button = styled.button`
  display: inline-flex;
	align-items: center;
	align-self: center;
	white-space: nowrap;
	min-height: 64px;
	padding: 18px 20px;
	background: var(--grad-orange);
  border-radius: var(--border-radius);
  transition: transform .3s;

	font-size: 16px;
	font-weight: bold;
	line-height: 22px;
	color: var(--text-white);

  img {
    margin-right: 12px;
  }

  &:hover {
    box-shadow: ${({ boxShadowHover = 'var(--box-shadow-orange)' }) => boxShadowHover};
		animation: pulse 0.9s infinite;
		animation-timing-function: linear;
	}

	@keyframes pulse {
		0% { transform: scale(1); }
		50% { transform: scale(1.1); }
		100% { transform: scale(1); }
	}
`

export default ButtonPulseOrange
