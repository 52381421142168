import React from 'react'

export default function LeadForm() {
    return (
        // <form className='flex flex-col lg:flex-row lg:w-2/3 py-6' id="6440313" data-uid="d649384ff4" data-sv-form="6440313" action='https://app.convertkit.com/forms/6440313/subscriptions' method='POST'>
        <form className='flex flex-col xl:flex-row  mt-4 xl:mb-2' id="6440313" data-uid="d649384ff4" data-sv-form="6440313" action='https://app.convertkit.com/forms/6440313/subscriptions' method='POST'>

            <input type='email' className='p-3 rounded-xl flex  xl:w-2/3 text-center xl:text-left text-sm' name="email_address" aria-label="Digite seu melhor e-mail" placeholder="Digite seu melhor e-mail" ></input>

            <button data-element="submit" className='xl:mx-4 p-3 my-2 xl:my-0  leadbutton text-slate-50  px-4  rounded-xl'>Quero ser avisado</button>
        </form>
    )
}
