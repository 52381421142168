import styled from 'styled-components'

export const Container = styled.div`
  background: var(--background-gray);
  padding: 60px 0;

  display: grid;
  gap: 50px 40px;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);

  .gridItem {
    padding: 0 60px;

    img {
      width: 70px;
      height: 70px;

      &:hover {
	      transform: scale(1.1);
	      transition: transform ease 0.5s;
      }
	  }

    .title {
      font-size: 22px;
      margin: 10px 0;
    }

    &:nth-child(3n-1) {
      border-left: 1px dashed #b6bec2;
      border-right: 1px dashed #b6bec2;
    }
  }

  @media (max-width: 1160px) {
    grid-template-columns: 1fr;

    .gridItem {
      display: flex;
      align-items: center;

      img {
        margin-left: 30px;
      }

      div {
        margin: 0 60px;
      }

      .title {
        margin: 10px 0 0;
      }

      &:nth-child(3n-1) {
        border: none;
      }
    }
  }

  @media (max-width: 720px) {
    padding: 48px 0;

    .gridItem {
      padding: 0 48px;
      align-items: flex-start;
      flex-direction: column;

      img {
        margin-left: 0;
      }

      div {
        margin: 0;
      }
    }
  }
`
