import styled from 'styled-components'

export const ContainerBlue = styled.section`
  width: 100%;
  background: var(--grad-blue-medium);
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: var(--max-width);
  padding: 120px 0 0;
  position: relative;

  .textsContainer {
    max-width: 440px;
    margin-bottom: 60px;
    position: relative;
    z-index: 99;
  }

  h2 {
    margin-bottom: 60px;
    color: var(--text-white);
    text-align: left;
  }

  .detailImg {
    opacity: 0.6;
    position: absolute;
    top: 0;
    right: 0;
  }

  .igorImg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media(max-width: 1160px) {
    padding: 120px 30px 0;
	}

	@media(max-width: 900px) {
    padding-top: 60px;

    h2 {
      margin-bottom: 30px;
    }

    .igorImg {
      opacity: 0.8;
      width: 114%;
      max-height: 604px;
    }
	}


	@media(max-width: 580px) {
    padding: 60px 15px 0;

    .textsContainer {
      max-width: 320px;
      margin: 0 auto;
    }

    .detailImg {
      display: none;
    }

    .igorImg {
      position: static;
      margin-left: -120px;
      width: 125%;
      opacity: 1;
    }
	}

	@media(max-width: 420px) {
    .igorImg {
      margin-left: -100px;
      width: 130%;
    }
  }
`
