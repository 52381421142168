import styled from 'styled-components'

import ButtonPulseOrange from '../ButtonPulseOrange'
import LinkPay from '../LinkPay'

function ButtonSubscribe({ center = true, margin, ...rest }) {
  return (
    <Container center={center} margin={margin} {...rest}>
      <LinkPay center>
        <ButtonPulseOrange>
          INSCREVA-SE AGORA MESMO
        </ButtonPulseOrange>
      </LinkPay>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: ${({ center }) => center ? 'center' : 'flex-start'};
  margin: ${({ margin = '0' }) => margin};
`

export default ButtonSubscribe
