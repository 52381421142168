import { useEffect, useState } from 'react'

import { Container } from './styles'
import { SubTitle } from '../../../components/Texts'
import CardCollapse from './components/CardCollapse'

import arrayFaq from './arrayFaq'

function FaqSection({ id }) {
  const [selected, setSelected] = useState(false)
  const [moreQuestions, setMoreQuestions] = useState(false)
  const [arrayQuestions, setArrayQuestions] = useState(arrayFaq.slice(0, 6))

  useEffect(() => {
    const arraySliced = moreQuestions ?
      arrayFaq :
      arrayFaq.slice(0, 6)

    setArrayQuestions(arraySliced)
  }, [moreQuestions])

  const openAccordion = index => {
    if (selected === index) setSelected(false)
    else setSelected(index)
  }

  const handleMoreQuestions = () => {
    setMoreQuestions(oldValue => !oldValue)
  }

  return (
    <Container id={id}>
      <SubTitle>FAQ - PERGUNTAS FREQUENTES</SubTitle>

      {arrayQuestions.map(({ question, response }, index) => (
        <CardCollapse
          key={index}
          question={question}
          response={response}
          selected={selected === index}
          onClick={() => openAccordion(index)}
        />
      ))}

      <button onClick={handleMoreQuestions}>
        {moreQuestions ? 'VER MENOS' : 'VER MAIS'}
      </button>
    </Container>
  )
}

export default FaqSection
