import styled from 'styled-components'

const CardScale = styled.div`
  width: 100%;
  border-radius: var(--border-radius);
  max-width: ${({ maxWidth = 'initial' }) => maxWidth};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0' }) => margin};
  transition:
    box-shadow 1s cubic-bezier(0.075, 0.82, 0.165, 1),
    transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    box-shadow: var(--box-shadow-card);
    transform: scale(1.01);
  }

  @media(max-width: 1024px) {
    box-shadow: var(--box-shadow-card);
    transform: scale(1.01);
  }
`

export default CardScale
