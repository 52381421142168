import { Button, Container } from './styles'
import LinkPay from '../LinkPay'

import logo from '../../../assets/logo.svg'
import { ContainerBlue, Content } from '../../pages/Home/HeroSection/styles'

function Header({ btnPay = true }) {
  return (
    <Container>
      <Content>
        <Container>
          <img src={logo} alt='logo progbr' />

          <div className='flex'>
            <nav>
              <a href='/#course-content'>O que você vai aprender?</a>
              <a href='/#testimonials'>Depoimentos</a>
              <a href='/#benefits'>Benefícios</a>
              <a href='/#igor'>Quem sou eu </a>
              <a href='/#faq'>FAQ</a>
            </nav>

            {btnPay && (
              <LinkPay>
                <Button>INSCREVA-SE AGORA</Button>
              </LinkPay>
            )}
          </div>
        </Container>
      </Content>
    </Container>
  )
}

export default Header
