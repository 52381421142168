import styled from 'styled-components'

export const ContainerBlue = styled.section`
  width: 100%;
  background: var(--grad-blue);
  // background: black;
  display: flex;
  justify-content: center;
`
export const ContainerBlack = styled.section`
  width: 100%;
  background: var(--grad-blue);
  // background: black;
  display: flex;
  justify-content: center;
`
export const Content = styled.div`
  width: 100%;
  max-width: var(--max-width);
  margin: 0 30px;

  @media(max-width: 940px) {
    margin: 0 15px;
  }
`

export const HeroContainer = styled.div`
  padding: 70px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  div {
    max-width: 550px;
    position: relative;
    z-index: 10;

    h1 {
      font-size: 64px;
      font-weight: 800;
      line-height: 70px;
      margin-bottom: 25px;
      color: var(--text-white);
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 34px;
      margin-bottom: 25px;
      color: var(--text-gray-light);
    }
  }

  img {
    max-width: 607px;
  }

  .slashContainer {
    width: 250px;
    overflow: hidden;
    position: absolute;
    right: -90px;
    bottom: -200px;

    .slash {
      height: 400px;
    }
  }

  @media(max-width: 1360px) {
    .slashContainer {
      display: none;
    }
  }

  @media(max-width: 1220px) {
    padding: 110px 0;

    div {
      h1 {
        font-size: 60px;
        line-height: 68px;
      }
    }

    > img {
      margin-left: -190px;
      max-width: 525px;
    }
  }

  @media(max-width: 940px) {
    div {
      max-width: 380px;

      h1 {
        font-size: 44px;
        line-height: 62px;
      }
    }
  }

  @media(max-width: 766px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 40px 0 90px;

    div {
      margin-top: -58px;

      h1 {
        font-size: 32px;
        line-height: 38px;
      }
    }

    > img {
      width: 100%;
      margin: 0 auto;
    }
  }

  @media(max-width: 520px) {
    padding: 0 0 90px;
  }
`
