import styled from 'styled-components'

import logoSilhouette from '../../../../assets/images/logo-silhouette.png'

export const ContainerBlue = styled.section`
  width: 100%;
  background: var(--grad-blue-medium);
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: var(--max-width);
  padding: 120px 0;
  position: relative;

  background: url(${logoSilhouette}) no-repeat center;
  background-size: auto 90%;

  h2 {
    color: var(--text-white);
    margin-bottom: 45px;
  }

  .slash {
    position: absolute;
    height: 250px;
    top: -110px;
  }

  @media(max-width: 1160px) {
    padding: 120px 30px;

    .slash {
      left: 10px;
    }
	}

	@media(max-width: 900px) {
    padding: 60px 30px;

    .slash {
      height: 200px;
      top: -90px;
      left: -10px;
    }
	}

	@media(max-width: 520px) {
    padding: 60px 15px;
	}
`
