import styled, { css } from 'styled-components'

export const Title = styled.h2`
  font-size: 64px;
  line-height: 70px;
  font-weight: 800;
  color: var(--text-dark);
  text-align: ${({ textAlign = 'center' }) => textAlign};

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}

  @media(max-width: 900px) {
    font-size: 46px;
    line-height: 64px;
  }

  @media(max-width: 520px) {
    font-size: 32px;
    line-height: 34px;
  }
`

export const SubTitle = styled.h3`
  font-size: 36px;
  line-height: 52px;
  font-weight: 800;
  color: var(--text-dark);
  text-align: ${({ textAlign = 'center' }) => textAlign};

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}

  @media(max-width: 900px) {
    font-size: 32px;
    line-height: 46px;
  }

  @media(max-width: 520px) {
    font-size: 22px;
    line-height: 28px;
  }
`

const TextBase = styled.p`
  margin: ${({ margin = '0' }) => margin};
  padding: ${({ padding = '0' }) => padding};
  color: ${({ color = 'var(--text-gray)' }) => color};
  font-weight: ${({ fontWeight = '400' }) => fontWeight};

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}

  ${({ bold }) => bold && css`
    color: var(--text-dark);
    font-weight: 800;
  `}
`

export const TextSmall = styled(TextBase)`
  font-size: ${({ fontSize = '16px' }) => fontSize};
  line-height: ${({ lineHeight = '24px' }) => lineHeight};
`

export const TextMedium = styled(TextBase)`
  font-size: ${({ fontSize = '18px' }) => fontSize};
  line-height: ${({ lineHeight = '24px' }) => lineHeight};
`

export const Text = styled(TextBase)`
  font-size: ${({ fontSize = '20px' }) => fontSize};
  line-height: ${({ lineHeight = '34px' }) => lineHeight};
`
