import { Container, Content } from './styles'
import { Text, Title } from '../../../components/Texts'
import GridCardDashed from '../../../components/GridCardDashed'
import TitleBrackets from './components/TitleBrackets'
import ButtonSubscribe from '../../../components/ButtonSubscribe'

import array from './arrayCourseContent'

import electron from '../../../../assets/icons/courseContent/Electron.png'

function CourseContentSection({ id }) {
  return (
    <Container id={id}>
      <Content>
        <Title textAlign='left'>O QUE VOCÊ VAI APRENDER?</Title>

        <div className='gridDashedContainer'>
          <TitleBrackets>FRONT END</TitleBrackets>

          <GridCardDashed arrayItems={array.frontend} />
        </div>

        <div className='gridDashedContainer'>
          <TitleBrackets>BACK END</TitleBrackets>

          <GridCardDashed arrayItems={array.backend} />
        </div>

        <div className='gridDashedContainer'>
          <TitleBrackets>NEGÓCIOS E VENDAS</TitleBrackets>

          <GridCardDashed arrayItems={array.negocios} />
        </div>

        <div className="cardElectron">
          <img src={electron} alt='Electron' />

          <div>
            <Text fontSize='22px' margin='10px 0' bold>
              APRENDA A TRANSFORMAR SEU SISTEMA WEB EM UM APLICATIVO DESKTOP
            </Text>

            <Text>
              Neste módulo bônus de Electron você vai aprender a criar aplicações desktop utilizando
              linguagens web como JavaScript, HTML, CSS e React.
            </Text>
          </div>
        </div>

        <ButtonSubscribe />
      </Content>
    </Container>
  )
}

export default CourseContentSection
