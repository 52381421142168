import { SubTitle } from '../../../../components/Texts'

import brackets from '../../../../../assets/icons/brackets.svg'

function TitleBrackets({ children }) {
  return (
    <div className='titleBrackets'>
      <img src={brackets} alt='brackets' />
      <SubTitle>{children}</SubTitle>
    </div>
  )
}

export default TitleBrackets
