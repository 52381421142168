import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from './pages/Home/index'
import Privacy from './pages/Privacy'
import Waitlist from './pages/Waitlist'

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path='/politicas'>
          <Privacy />
        </Route>
        <Route path='/'>
          <Waitlist />
        </Route>
      </Switch>
    </Router>
  )
}

export default Routes
