import { Container } from './styles'
import CardBigNumber from './CardBigNumber'
import { SubTitle } from '../../../components/Texts'
import ButtonSubscribe from '../../../components/ButtonSubscribe'

function BigNumbersSection({ id }) {
  return (
    <Container id={id}>
      <SubTitle>TUDO O QUE VOCÊ PRECISA EM UM SÓ LUGAR</SubTitle>

      <div className='cardsContainer'>
        <CardBigNumber number='2000' description='ALUNOS' plus />
        <CardBigNumber number='500' description='AULAS' plus />
        <CardBigNumber number='10' description='TECNOLOGIAS FULL STACK' />
      </div>

      <ButtonSubscribe className='ButtonSubscribe' />
    </Container>
  )
}

export default BigNumbersSection
