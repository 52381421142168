import { Container } from './styles'
import TitleAndDescriptionBrackets from '../../../components/TitleAndDescriptionBrackets'
import ButtonSubscribe from '../../../components/ButtonSubscribe'
import GridStudents from '../../../components/GridStudents'

import arrayStudents from './arrayStudents'

function ContentTestimonialsSection({ id }) {
  return (
    <Container id={id}>
      <TitleAndDescriptionBrackets
        title='CONTEÚDO DEV WEB COMPLETO'
        description='SAIA DO ABSOLUTO ZERO E APRENDA TUDO O QUE VOCÊ PRECISA PARA ENTRAR NO MERCADO DE PROGRAMAÇÃO'
      />

      <GridStudents
        columns='3'
        arrayStudents={arrayStudents}
        className='grid'
      />

      <ButtonSubscribe />
    </Container>
  )
}

export default ContentTestimonialsSection
