import Routes from './routes'
import GlobalStyle from '../styles/GlobalStyle'
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel'
import { useEffect } from 'react';
ReactGA.initialize('UA-80968708-1');

const options = {
  autoConfig: true,
  debug: false,
}

ReactPixel.init()
ReactPixel.init('330504420914076', null, options)

function App() {

  useEffect(() => {
    ReactPixel.pageView()
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [])

  return (
    <>
      <Routes />
      <GlobalStyle />
    </>
  )
}

export default App
