import React from 'react'
import NewLeadCard from './NewLeadCard'
import UIUXDesignProCard from './UIUXDesignProCard'


function WaitSection() {
    return (

        <div>
            <NewLeadCard></NewLeadCard>
            <UIUXDesignProCard></UIUXDesignProCard>
        </div>
    )
}

export default WaitSection