
import FooterSection from './FooterSection'
import Header from './Header'
import LeadCard from './LeadCard'
import WaitSection from './WaitSection'



function Waitlist() {
    //   const pop_up_vendas = {
    //     dueDay: '15/april/2024',
    //     dueTime: '23:59:00',
    //     launchSeconds: 50
    //   }

    return (
        <div className='iplodevcontainer mx-auto  min-h-screen flex flex-col justify-between'>

            <div className=''>
                {/* <Header></Header> */}
                {/* <LeadCard></LeadCard> */}
                <WaitSection></WaitSection>
            </div>
            <FooterSection id='FooterSection' />

            <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
        </div>
    )
}



export default Waitlist
