import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
// background: black;
  .cnpj {
    font-size: 12.8px;
    line-height: 20px;
    color: var(--text-gray-light);
    margin: 0 10px 5px;
    text-align: center;
  }
`

export const Content = styled.section`
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  padding: 10px 0 0;

  header {
    padding: 0;
  }

  .textPrivacy {
    margin-left: 25px;
    white-space: nowrap;
  }

	@media (max-width: 1160px) {
    padding: 10px 30px 0;
    justify-content: space-between;

    header {
      width: fit-content;

      .flex {
        display: none;
      }
    }
	}

	@media (max-width: 520px) {
    padding: 10px 15px 15px;
    flex-direction: column;

    .textPrivacy {
      margin-left: 0;
    }
	}
`
