import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  max-width: var(--max-width);
  padding: 60px 0;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cardsContainer {
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ButtonSubscribe {
    margin: 60px 0 0;
    display: none;
  }

  @media(max-width: 1160px) {
    padding: 60px 30px;
    margin: 0;

    .cardsContainer{
      flex-direction: column;
    }
  }

  @media(max-width: 520px) {
    padding: 48px 15px;

    .cardsContainer{
      margin-top: 48px;
    }

    .ButtonSubscribe {
      display: flex;
    }
  }
`
