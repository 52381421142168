import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  max-width: var(--max-width);
  padding: 120px 0 60px;
  position: relative;

  @media (max-width: 1160px) {
    padding: 120px 30px 60px;
  }

  @media (max-width: 900px) {
    padding: 60px 30px 48px;
  }

  @media (max-width: 520px) {
    padding: 60px 15px 48px;
  }
`
